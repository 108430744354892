(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('body', $container);

        if ($root.length === 0) return;

        // Compute scrollbar width
        document.documentElement.style.setProperty(
            '--scrollbar-width',
            $.scrollbar.getWidth() + 'px',
        );
    });
})(jQuery, window.app);
